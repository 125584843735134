import React from "react"

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Link } from "gatsby"

const Bold = ({ children }) => (
  <span className="bold" style={{ fontWeight: "bold" }}>
    {children}
  </span>
)
const Italic = ({ children }) => (
  <span style={{ fontVariant: "italic" }}>{children}</span>
)
const Text = ({ children }) => (
  <div style={{ marginBottom: 10, lineHeight: 1.5 }}>{children}</div>
)
const Head2 = ({ children }) => (
  <h2
    style={{
      fontWeight: "bold",
      color: "#567",
      lineHeight: 1.2,
      margin: "0 0 20px",
    }}
  >
    {children}
  </h2>
)

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  },
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: node => {
      debugger
      return (
        <Link
          to={`/${node.data.target.sys.contentType.sys.id}/${
            node.data.target.fields.slug["ca"]
          }`}
        >
          {node.data.target.fields.name["ca"]}
        </Link>
      )
    },
    [INLINES.HYPERLINK]: (node, next) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {node.content[0].value}
      </a>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_2]: (node, children) => <Head2>{children}</Head2>,
  },
}

const formatRichText = content => documentToReactComponents(content, options)

export default formatRichText
